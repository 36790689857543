export default [
  {
    path: "/",
    text: "Startseite",
  },
  {
    path: "/firma",
    text: "Firma",
  },
  {
    path: "/galerie",
    text: "Galerie",
  },
  {
    path: "/leistungen",
    text: "Leistungen",
  },
  {
    path: "/konfigurator",
    text: "Konfigurator",
  },
  {
    path: "/kontakt",
    text: "Kontakt",
  },
]
