import React from "react"
import {
  FaDraftingCompass,
  FaHardHat,
  FaFeatherAlt,
  FaAccusoft,
} from "react-icons/fa"

export default [
  {
    icon: <FaAccusoft />,
    title: "Bedachung",
    text: "Dacheindeckung, Wärmedämmung, Sanierung und Belichtung",
  },
  {
    icon: <FaDraftingCompass />,
    title: "Bauklempnerei",
    text:
      "Erstellung von Dachrinnen, Regenfallrohren, Mauerabdeckungen und Metalleindeckung",
  },
  {
    icon: <FaHardHat />,
    title: "Holzbau",
    text: "Aufbringen von Schalungen, Errichtung von Gauben, Holzarbeiten",
  },
  {
    icon: <FaFeatherAlt />,
    title: "Sonstiges",
    text:
      "Blitzschutz, Balkon- und Terrassensanierung inklusive Plattenarbeiten, Wartung",
  },
]
