export default [
  {
    path: "/",
    text: "Startseite",
  },
  {
    path: "/impressum",
    text: "Impressum",
  },
  {
    path: "/datenschutz",
    text: "Datenschutz",
  },
  {
    path: "/kontakt",
    text: "Kontakt",
  },
]
