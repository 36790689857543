import React from "react"
import Anilink from "gatsby-plugin-transition-link/AniLink"
import linksfooter from "../constants/linksfooter"
import styles from "../css/footer.module.css"
import Innung from "../../static/images/innung.gif"

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.links}>
        {linksfooter.map((item, index) => {
          return (
            <Anilink fade key={index} to={item.path}>
              {item.text}
            </Anilink>
          )
        })}
      </div>
      <div className={styles.copyright}>
        <a href="https://www.baugewerks-innung-cochem-zell.de/content.php?hkpin=1">
          {" "}
          <img src={Innung} alt="Logo der Dachdeckerinnung Cochem-Zell" />
        </a>
        <br />
        <br />
        Mitglied der Dachdeckerinnung Cochem-Zell
      </div>
      <div className={styles.copyright}>
        copyright &copy; Paul Berenz {new Date().getFullYear()}
      </div>
      <div className={styles.copyright}></div>
    </footer>
  )
}

export default Footer
