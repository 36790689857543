import React from "react"
import Navbar from "./Navbar"
import Footer from "./Footer"
import "./layout.css"
import ProjectWithUs from "../components/Contact/ProjectWithUs"
import Service from "../components/Home/Service"

const Layout = ({ children }) => {
  return (
    <main>
      <Navbar />
      {children}
      <Service />
      <ProjectWithUs />
      <Footer />
    </main>
  )
}

export default Layout
