import React from "react"
import styled from "styled-components"

const ProjectWithUs = () => {
  return (
    <ProjectWrapper>
      <div className="project_info">
        <h3>Möchten Sie mit uns ein Projekt starten?</h3>
        <p>Rufen Sie uns an oder schreiben Sie uns eine E-Mail</p>
      </div>
      <div className="project_phones">
        <a href="tel:0049026714808">+49 (0) 26 71 - 48 08</a>
        <a href="mailto:info@paul-berenz.de">info@paul-berenz.de</a>
      </div>
    </ProjectWrapper>
  )
}

const ProjectWrapper = styled.section`
  background: var(--primaryColor);
  color: var(--mainWhite);
  padding: 2rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;

  .project_phones {
    display: flex;
    flex-direction: column;

    a {
      text-decoration: none;
      color: var(--mainWhite);
      border: 2px solid var(--mainWhite);
      padding: 1rem;
      text-align: center;
      border-radius: 2rem;
      width: 15rem;
      margin-top: 1rem;
      transition: var(--mainTransition);

      &:hover {
        background: var(--mainWhite);
        color: var(--primaryColor);
        transform: scale(1.1);
      }
    }
  }

  @media (min-width: 760px) {
    flex-direction: row;
  }
`

export default ProjectWithUs
